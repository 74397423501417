<template>
  <div>
    <template v-if="!showVerificationCodeField">
      <v-row>
        <v-col col="6" class="tw-py-0">
          <UserAvatar :user="user" />
        </v-col>
      </v-row>
      <v-row>
        <v-row>
          <v-col>
            <v-form>
              <v-col cols="12">
                <j-text-field
                  color="secondary"
                  background-color="#ffffff"
                  label="First Name"
                  v-model="firstName"
                  customClasses="tw-text-left tw-bg-input-background"
                />
              </v-col>
              <v-col cols="12">
                <j-text-field
                  outlined
                  color="secondary"
                  background-color="#ffffff"
                  label="Last Name"
                  v-model="lastName"
                  customClasses="tw-text-left tw-bg-input-background"
                />
              </v-col>
              <v-col cols="12" class="tw-relative">
                <span
                  class="tw-text-royal-blue tw-text-xxs tw-absolute tw-top-14 tw-pt-1 tw-right-8 tw-cursor-pointer"
                  v-if="
                    !user.isPhoneVerified &&
                    validPhone &&
                    phone &&
                    phone.length !== 0
                  "
                  @click="verifyNumber()"
                  >Verify</span
                >
                <span
                  class="tw-text-royal-green tw-text-xxs tw-absolute tw-top-14 tw-pt-1 tw-right-8 tw-cursor-pointer"
                  v-if="user.isPhoneVerified"
                  >Verified</span
                >
                <j-tel-input
                  label="Phone"
                  :inputOptions="{ placeholder: '(888) 888-8888' }"
                  :defaultCountry="defaultCountry"
                  @blur="blurNumber($event)"
                  v-model="phone"
                  customClasses="tw-text-left"
                ></j-tel-input>
              </v-col>
              <v-col cols="12">
                <j-text-field
                  :disabled="true"
                  color="secondary"
                  background-color="#ffffff"
                  label="Email"
                  v-model="email"
                  customClasses="tw-text-left tw-bg-input-background"
                />
                <p
                  class="tw-text-left tw-text-light-grey tw-text-xxs tw-ml-4 tw-mt-2"
                >
                  Contact support@letsjetty.com to update your email.
                </p>
              </v-col>
              <v-col cols="12">
                <router-link
                  class="tw-text-blueish tw-text-left tw-underline tw-block tw-ml-4 tw-text-xs"
                  :to="{
                    name: 'Notifications'
                  }"
                >
                  Check your notification settings to receive trip
                  updates.</router-link
                >
              </v-col>
            </v-form>
          </v-col>
        </v-row>
      </v-row>
    </template>
    <template v-else>
      <v-col
        cols="12"
        v-if="
          firstName && lastName && validPhone && phone && phone.length !== 0
        "
      >
        <span
          class="tw-block tw-text-dark-green tw-text-left tw-font-semibold tw--mt-3"
          >Enter Verification Code</span
        >
        <span
          class="tw-block tw-text-left tw-text-light-grey tw-text-xs tw-mb-2"
          >Enter code sent to {{ phone }}.
          <a
            href="#"
            @click.prevent="verifyNumber('resend')"
            class="tw-text-charcoal tw-font-bold tw-text-xs"
            >Resend.</a
          ></span
        >
        <validation-provider
          v-slot="{ errors }"
          name="Verification Code"
          rules="required"
        >
          <!--  <j-text-field
            color="secondary"
            v-model="code"
            type="text"
            :hide-details="errors.length === 0"
            :error-messages="errors"
            customClasses="tw-text-left"
          /> -->
          <v-otp-input
            color="secondary"
            length="5"
            v-model="code"
            :error-messages="errors"
            :hide-details="errors.length === 0"
          ></v-otp-input>
        </validation-provider>
        <p
          class="tw-text-left tw-text-xs tw-leading-5 tw-text-red tw-w-90 tw-mx-auto"
          v-if="codeVerificationError"
        >
          We were unable to verify the code. Tap resend to try again and ensure
          your phone number is correct.
        </p>
        <v-btn
          @click="verifyCode()"
          x-large
          rounded
          block
          depressed
          class="j-btn tw-bg-chartreuse tw-tracking-normal tw-mt-4"
          :disabled="!code || code.length < 5"
          >Verify Number</v-btn
        >
      </v-col>
    </template>
  </div>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "EditProfile",
  props: ["user"],
  data() {
    return {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phone: this.user.phone,
      email: this.user.email,
      validPhone: false,
      fullNumber: null,
      showVerificationCodeField: false,
      code: null,
      defaultCountry: "US",
      codeVerificationError: false,
      codeVerificationID: null
    };
  },
  components: {
    UserAvatar
  },
  methods: {
    verifyCode() {
      this.$store
        .dispatch("consume-phone-verification/create", {
          id: this.codeVerificationID,
          code: this.code
        })
        .then(() => {
          this.user.isPhoneVerified = true;
          this.showVerificationCodeField = false;
          this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
            button: true,
            text: "Save",
            isDisabled: false,
            method: this.save,
            bgColor: "tw-bg-chartreuse"
          });
        })
        .catch((err) => {
          this.codeVerificationError = true;
          console.error(err);
        });
    },
    async verifyNumber(type = null) {
      const patchUser = await this.$store.dispatch("users/patch", [
        this.user.id,
        {
          phone: this.fullNumber
        }
      ]);

      if (patchUser && !patchUser.isPhoneVerified) {
        const verifyNumberRes = await this.$store.dispatch(
          "create-phone-verification/create",
          {}
        );
        this.codeVerificationID = verifyNumberRes.id;
      }

      if (type) {
        this.$store.dispatch("meta/showGlobalAlert", {
          type: "success",
          text: "Code was re-sent.",
          timeout: 2000
        });
      } else {
        this.showVerificationCodeField = true;
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    },
    blurNumber(e) {
      this.validPhone = e.valid;
      if (this.validPhone && e.number) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
        this.fullNumber = e.number;
      } else {
        this.fullNumber = null;
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
      const phoneNumber = parsePhoneNumberFromString(e.number);
      if (phoneNumber) {
        this.defaultCountry = phoneNumber.country;
      } else {
        this.defaultCountry = "US";
      }
    },
    setSaveButtonState() {
      this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
        text: "Saved!",
        bgColor: "tw-bg-dark-green",
        textColor: "tw-text-chartreuse"
      });
      setTimeout(() => {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          text: "Save",
          bgColor: "tw-bg-chartreuse",
          textColor: "tw-text-charcoal"
        });
      }, 3000);
    },
    save() {
      const updatedUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        phone: this.fullNumber
        //email: this.email,
      };
      this.$store
        .dispatch("users/patch", [this.user.id, updatedUser])
        .then(() => {
          this.setSaveButtonState();
          this.$store.dispatch("users/get", this.user.id);
        });
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Edit Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });

    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      text: "Save",
      isDisabled: false,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });

    this.fullNumber = this.user.phone;

    const phoneNumber = parsePhoneNumberFromString(this.user.phone);
    if (phoneNumber) {
      this.defaultCountry = phoneNumber.country;
    } else {
      this.defaultCountry = "US";
    }
  },
  destroyed() {
    this.$store.commit("meta/setClickedVerificationBanner", false);
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  }
};
</script>
