var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.showVerificationCodeField)?[_c('v-row',[_c('v-col',{staticClass:"tw-py-0",attrs:{"col":"6"}},[_c('UserAvatar',{attrs:{"user":_vm.user}})],1)],1),_c('v-row',[_c('v-row',[_c('v-col',[_c('v-form',[_c('v-col',{attrs:{"cols":"12"}},[_c('j-text-field',{attrs:{"color":"secondary","background-color":"#ffffff","label":"First Name","customClasses":"tw-text-left tw-bg-input-background"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('j-text-field',{attrs:{"outlined":"","color":"secondary","background-color":"#ffffff","label":"Last Name","customClasses":"tw-text-left tw-bg-input-background"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('v-col',{staticClass:"tw-relative",attrs:{"cols":"12"}},[(
                  !_vm.user.isPhoneVerified &&
                  _vm.validPhone &&
                  _vm.phone &&
                  _vm.phone.length !== 0
                )?_c('span',{staticClass:"tw-text-royal-blue tw-text-xxs tw-absolute tw-top-14 tw-pt-1 tw-right-8 tw-cursor-pointer",on:{"click":function($event){return _vm.verifyNumber()}}},[_vm._v("Verify")]):_vm._e(),(_vm.user.isPhoneVerified)?_c('span',{staticClass:"tw-text-royal-green tw-text-xxs tw-absolute tw-top-14 tw-pt-1 tw-right-8 tw-cursor-pointer"},[_vm._v("Verified")]):_vm._e(),_c('j-tel-input',{attrs:{"label":"Phone","inputOptions":{ placeholder: '(888) 888-8888' },"defaultCountry":_vm.defaultCountry,"customClasses":"tw-text-left"},on:{"blur":function($event){return _vm.blurNumber($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('j-text-field',{attrs:{"disabled":true,"color":"secondary","background-color":"#ffffff","label":"Email","customClasses":"tw-text-left tw-bg-input-background"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('p',{staticClass:"tw-text-left tw-text-light-grey tw-text-xxs tw-ml-4 tw-mt-2"},[_vm._v(" Contact support@letsjetty.com to update your email. ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('router-link',{staticClass:"tw-text-blueish tw-text-left tw-underline tw-block tw-ml-4 tw-text-xs",attrs:{"to":{
                  name: 'Notifications'
                }}},[_vm._v(" Check your notification settings to receive trip updates.")])],1)],1)],1)],1)],1)]:[(
        _vm.firstName && _vm.lastName && _vm.validPhone && _vm.phone && _vm.phone.length !== 0
      )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"tw-block tw-text-dark-green tw-text-left tw-font-semibold tw--mt-3"},[_vm._v("Enter Verification Code")]),_c('span',{staticClass:"tw-block tw-text-left tw-text-light-grey tw-text-xs tw-mb-2"},[_vm._v("Enter code sent to "+_vm._s(_vm.phone)+". "),_c('a',{staticClass:"tw-text-charcoal tw-font-bold tw-text-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.verifyNumber('resend')}}},[_vm._v("Resend.")])]),_c('validation-provider',{attrs:{"name":"Verification Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-otp-input',{attrs:{"color":"secondary","length":"5","error-messages":errors,"hide-details":errors.length === 0},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,false,1753313025)}),(_vm.codeVerificationError)?_c('p',{staticClass:"tw-text-left tw-text-xs tw-leading-5 tw-text-red tw-w-90 tw-mx-auto"},[_vm._v(" We were unable to verify the code. Tap resend to try again and ensure your phone number is correct. ")]):_vm._e(),_c('v-btn',{staticClass:"j-btn tw-bg-chartreuse tw-tracking-normal tw-mt-4",attrs:{"x-large":"","rounded":"","block":"","depressed":"","disabled":!_vm.code || _vm.code.length < 5},on:{"click":function($event){return _vm.verifyCode()}}},[_vm._v("Verify Number")])],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }